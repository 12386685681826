<template>
  <div class="position-permissions-config">
<!--    <el-tabs style="margin-bottom: 20px" :value="activeName" @tab-click="handleTabClick">-->
<!--      <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab, index) in tabArr" :key="index">-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
    <fixed-action-bar>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import FixedActionBar from "../../../../base/layout/FixedActionBar";
  export default {
    components: {FixedActionBar},
    data() {
      return {
        loading: true,
        // activeName: "CycPositionPermissions",
        // tabArr: [
        //   {label: "会费与届数", name: "CycTerm"},
        //   {label: "职务展示", name: "CycPositionShow"},
        //   {label: "对接人配置", name: "CycDocking"},
        //   {label: "邀请码配置", name: "CycInvitationCode"},
        //   {label: "权限配置", name: "CycPositionPermissions"}
        // ],
        config: null,
      }
    },
    created() {
      this.getConfig()
    },
    methods: {
      // handleTabClick(e) {
      //   let routerName = e.paneName;
      //   // 使用push()、replace()进行导航时，不能重复导航到当前路由。
      //   if (routerName !== this.activeName) {
      //     this.$router.push({name: routerName})
      //   }
      // },
      getConfig() {
        this.loading = false;
      },
      saveConfig() {

      }
    },
  }
</script>

<style scoped>
  .position-permissions-config {

  }
</style>
